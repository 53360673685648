import React from 'react';
import { useNavigate } from 'react-router-dom';

const Header = () => {

    const nav = useNavigate()

    return (
        <header className="sticky-top bg-white text-white py-3 px-4 d-flex justify-content-between align-items-center">
            <div className="logo">
                <a href='/' className='text-decoration-none'><img src="/logo.png" alt="Logo" style={{ width: 200 }} /></a>
            </div>
            <nav>
                <ul className="nav d-flex gap-3">
                    <li className="nav-item"><button className="text-dark btn btn-white" onClick={() => nav("/")}>Home</button></li>
                    <li className="nav-item"><button className="text-dark btn btn-white" onClick={() => nav("/services")}>Services</button></li>
                    {/* <li className="nav-item"><button className="text-dark btn btn-white" onClick={() => nav("/contact")}>Contact</button ></li> */}
                </ul>
            </nav>
        </header>
    );
};

export default Header;
