import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";
import Services from "./components/Services";
import Detail from "./components/Detail";

function App() {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />}></Route>
                    <Route path="/services" element={<Services />}></Route>
                    <Route path="/services/:title" element={<Detail />}></Route>
                </Route>
            </Routes>
        </div>
    );
}

export default App;
