import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Footer = () => {
    return (
        <footer className="bg-white text-dark pt-4 pb-5 fade-in">
            <div className="container">
                <div className="row">
                    <div className="col-md-4  text-start">
                        <h5>Monozuna Inc.</h5>
                        <p className='font-size-10'>
                            House 2/3, Block A, Flat 4/A<br />
                            Lalmatia,  Mohammadpur,<br />
                            Dhaka - 1207, Bangladesh<br />
                            Phone: +880-1321-747472<br />
                            License No. TRAD/DNCC/049791/2023<br />
                        </p>
                    </div>
                    <div className="col-md-4">
                        <h5>Services</h5>
                        <ul className="list-unstyled font-size-10">
                            <li><a href="/services/digital-transforamtion" className='link-dark text-decoration-none'>Digital Transformation</a></li>
                            <li><a href="/services/cloud-based-software-solution" className='link-dark text-decoration-none'>Cloud-Based Software Solution</a></li>
                            <li><a href="/services/data-management" className='link-dark text-decoration-none'>Data Management</a></li>
                            <li><a href="/services/ai-driven-solution" className='link-dark text-decoration-none'>AI Driven Solution</a></li>
                            <li><a href="/services/outsourcing" className='link-dark text-decoration-none'>Outsourcing</a></li>
                            <li><a href="/services/digital-product-resale" className='link-dark text-decoration-none'>Digital Product Resale</a></li>
                        </ul>
                    </div>
                    <div className="col-md-4 text-end">
                        <h5>Follow Us</h5>
                        <ul className="list-unstyled font-size-10">
                            <li><a className='link-dark text-decoration-none' href="https://www.facebook.com/profile.php?id=61567099704359">Facebook</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer >
    );
};

export default Footer;
