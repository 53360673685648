import React from 'react';

const Overview = () => {
    return (
        <div className='bg-light py-5'>
            <div className="container">
                <h2>OVERVIEW</h2>
                <p className='mt-4 text-align-justify'>
                    At Monozuna, we are more than just an IT service provider—we are your trusted technology partner committed to driving innovation, excellence, and growth. From digital transformation to business automation and cloud-based software solutions, we deliver bespoke technology services that empower businesses to thrive in a dynamic digital landscape.
                    Our expertise includes AI-driven solutions, ERP implementation, technology R&D, outsourcing, and device reselling, offering comprehensive, end-to-end solutions tailored to meet the unique needs of our clients. At Monozuna, we help businesses streamline operations, enhance efficiency, and achieve sustainable growth through cutting-edge technology and strategic partnerships.
                    <br /><br />
                    Driven by the belief that technology is a catalyst for success, we help organizations transform their challenges into opportunities, ensuring they stay ahead in an ever-evolving digital landscape. At Monozuna, we don't just build solutions—we build pathways to success while striving to make technology a force for good in achieving sustainable development.
                </p>
                <h2 className='mt-5'>VISION</h2>
                <p className='mt-4 text-align-justify'>To lead the way in providing innovative technology services that enhance business excellence and improve quality of life, while contributing to a more sustainable and equitable world. Through our commitment to technological advancement and sustainability, Monozuna aims to align with the United Nations Sustainable Development Goals (SDGs) by fostering inclusive economic growth and promoting responsible consumption and production in all that we do.</p>
            </div>
        </div>
    );
};

export default Overview;
