import Banner from "./Banner";
import FadeIn from "./FadeIn";
import Overview from "./Overview";
import Projects from "./Projects";
import Services from "./Services";

const Home = () => {
    return (
        <div>
            <FadeIn>
                <Banner />
            </FadeIn>
            <FadeIn>
                <Overview />
            </FadeIn>
            <FadeIn>
                <Services />
            </FadeIn>
            <FadeIn>
                {/* <Projects /> */}
            </FadeIn>
        </div>
    );
};
export default Home;