import React from 'react';
// import './Banner.css';  // Add your custom styles for animation

const Banner = () => {
    return (
        <div className="banner">
            <div className="banner-text animated fadeIn">
            </div>
        </div>
    );
};

export default Banner;
