import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const Detail = () => {
    const [htmlContent, setHtmlContent] = useState("");

    const params = useParams()


    useEffect(() => {
        // Fetch the HTML file from the public folder
        fetch(`/html/${params?.title}.html`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.text();
            })
            .then((html) => setHtmlContent(html))
            .catch((error) => console.error("Error loading the HTML file:", error));
    }, []);



    const services = [
        {
            page: 'digital-transformation',
            title: 'Digital Transformation',
            description:
                'Monozuna’s digital transformation services empower businesses to adopt cutting-edge technologies, automate processes, and enhance customer experiences. By reimagining business models and leveraging technology, we help organizations stay competitive in an evolving digital world. Monozuna ensures that businesses not only transition to digital environments but also thrive in them by building sustainable, efficient, and future-ready systems. Let us transform your vision into a reality with our comprehensive solutions.',
            image: '/static/dx.png',
        },
        {
            page: 'cloud-based-software-solution',
            title: 'Cloud-Based Software Solutions',
            description:
                'Monozuna’s cloud-based software solutions enable businesses to leverage the power of the cloud for increased scalability, security, and accessibility. By shifting from traditional on-premise systems to the cloud, organizations can streamline operations, reduce IT infrastructure costs, and enhance collaboration.',
            image: '/static/web.png',
        },
        {
            page: 'data-management',
            title: 'Data Management',
            description:
                'Monozuna empowers businesses to harness the potential of their data through comprehensive Big Data Management services. From data acquisition to transformation, storage, and insight extraction, we cover the entire data engineering lifecycle. Our expertise ensures seamless handling of large-scale datasets, enabling organizations to make informed decisions and gain actionable insights. By leveraging cutting-edge tools and technologies, Monozuna helps you turn raw data into valuable intelligence, driving innovation and efficiency across your business operations.',
            image: '/static/data.png',
        },
        {
            page: 'ai-driven-solution',
            title: 'AI Driven Solution',
            description:
                'Harness the power of artificial intelligence with Monozuna’s cutting-edge AI-driven solutions. Whether optimizing business processes, enhancing customer experiences, or enabling data-driven decision-making, our AI services are designed to unlock unprecedented levels of efficiency and innovation. From custom machine learning models to intelligent automation, we bring advanced AI technology within reach for businesses looking to lead in a competitive, tech-driven world.',
            image: '/static/ai.png',
        },
        {
            page: 'outsourcing',
            title: 'Outsourcing',
            description:
                'Monozuna offers reliable technology development partnerships to help businesses scale effortlessly. Our outsourcing services span both software and hardware domains, providing expertise, skilled resources, and operational support. Whether you need custom software development, hardware integration, or end-to-end project assistance, we collaborate with you to deliver innovative solutions that align with your goals. With Monozuna as your partner, you gain the flexibility and expertise needed to stay ahead of the curve.',
            image: '/static/outsourcing.png',
        },
        {
            page: 'digital-product-resale',
            title: 'Digital Product Resale',
            description:
                'Monozuna simplifies access to top-tier IT products with our technology product reselling services. From computers and laptops to networking devices, cameras, and cutting-edge gadgets, we provide businesses with reliable, high-quality technology solutions. Our curated inventory ensures you receive the latest innovations at competitive prices, backed by expert advice and support to help you make the best technology investments for your organization.',
            image: '/static/reselling.png',
        },
    ];

    const service = services.find(service => service.page === params.title)

    return (

        <div className="container my-5">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb font-size-11">
                    <li className="breadcrumb-item"><a href="/" className="link-dark">Home</a></li>
                    <li className="breadcrumb-item"><a href="/services" className="link-dark">Services</a></li>
                    <li className="breadcrumb-item active" aria-current="page">{params?.title}</li>
                </ol>
            </nav>
            <h1 className="text-center fw-bold mt-5">{service?.title?.toUpperCase()}</h1>
            <div className="d-flex justify-content-center my-5">
                <img src={service?.image} alt={service?.title} />
            </div>
            <p className="my-4">{service?.description}</p>
            <div className="my-4" dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
        </div>
    );
}
export default Detail;